<template>
  <div>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            Unreconciled Bank Transactions
          </sdHeading>
           <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync: 
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
               <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col>
        </a-row
        >
      </template>
      <template v-slot:buttons>
        <a-row :gutter="25" style="justify-content: center">
          <a-col>
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-group">
                  <div class="sort-group">
                    <a-select @change="onSorting" defaultValue="Active">
                      <a-select-option value="Active">Active</a-select-option>
                      <a-select-option value="Ignored"
                        >Dismissed</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
          </a-col>
        </a-row>
      </template>
    </sdPageHeader>
    <Main>
    <Description text="List of Bank Transactions which are not reconciled." />
      <sdCards headless v-if="loading">
        <div class="spin">
          <a-spin />
        </div>
      </sdCards>
      <sdCards headless v-else>
        <template v-if="selectedoption == 'Active'">
          <a-row :gutter="15" style="padding: 10px">
            
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="TableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                >
                </a-table>
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('A')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                        Active</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="ignoreTableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: ignoreTableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                />
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import { Main, TableWrapper,BasicFormWrapper } from "../../../../styled";
import {
  TopToolBox,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import Description from './../Description.vue';
import { defineComponent, computed, ref, onMounted,reactive } from "vue";
import { dispatch, useStore } from "vuex";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default defineComponent({
  name: "xero_unreconciled",
  components: {
    Main,
    TableWrapper,
    BasicFormWrapper,
    ProjectSorting,
    TopToolBox,
    Description,
    UserTableStyleWrapper,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);

    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };
    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      let xero_get_datas = [];
      state.XeroUnreconciled.data = (JSON.parse(localStorage.getItem("search_data")));
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        if(!((data.name).toLowerCase()).includes(substring.toLowerCase()) && !((data.bankaccount_name).toLowerCase()).includes(substring.toLowerCase()) && !((data.total)).includes(substring)){
          (state.XeroUnreconciled.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      state.XeroUnreconciled.data = xero_get_datas;
    };
    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroUnreconciled.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };
    /*const openurl = (id) => {
      window.open("https://go.xero.com/Contacts/View/" + id, "_blank");
      // location.href = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
    };*/
    const openurl = (id, account_id,action_url) => {
      window.open(
        action_url,
        "_blank"
      );
    };
    const getdata = () => {
      loading.value = true;
      dispatch("getunreconciledData").then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            state.XeroUnreconciled.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroUnreconciled.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
          state.XeroUnreconciled.data = [];
        }
      );
      
    };

    const exportToCSV = () => {
      const inputData = state.XeroUnreconciled.data.map((unreconcile) => {
        const {
          id,
          bankaccount_name,
          reference,
          banktransaction_id,
          bank_account_id,
          currency_code,
          total,
          name,
          to_date,
          from_date,
          record_type,
          action_url,
        } = unreconcile;
        return {
          key: id,
          bankaccount_name: bankaccount_name + from_date + to_date,
          Name: name,
          Gross_amount: total,
          record_type: record_type,
        };
      });

      var linedata = [];

      for (let i = 0; i < inputData.length; i++) {
        linedata.push({
          "Client Name": inputData[i]["bankaccount_name"],
          "Account Code": inputData[i]["currency_code"],
          "Gross Amt": inputData[i]["subtotal"],
          "Record Type": inputData[i]["record_type"],
        });
        
      }
      var linedata1 = [];
      linedata1.push({
      Point : "Unreconciled Bank Transactions",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name +
        " Unreconciled Bank Transactions " +
        orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
    const getignoredata = () => {
      loading.value = true;
      console.log("getignoredata");
      dispatch("unreconciledignoreData").then(
        (response) => {
          console.log(response.data);
          if (response.status == 200) {
            state.XeroUnreconciled.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            loading.value = false;
            state.XeroUnreconciled.data = [];
            // onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.XeroUnreconciled.data = [];
        }
      );
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreunreconciled", data).then(
        (response) => {
          console.log("ignoreunreconciled");
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };
    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreunreconciledBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const TableColumns = [
      {
        title: "Bank Name",
        dataIndex: "bankaccount_name",
        key: "bankaccount_name",
        sorter: (a, b) => a.bankaccount_name.length - b.bankaccount_name.length,
        sortDirections: ["descend", "ascend"],
      },
      // {
      //   title: "Description",
      //   dataIndex: "reference",
      //   key: "reference",
      //   sorter: (a, b) => a.reference.length - b.reference.length,
      //   sortDirections: ["descend", "ascend"],
      // },
      {
        title: "Client_name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },

      {
        title: "Gross Amt",
        dataIndex: "subtotal",
        key: "subtotal",
        sorter: (a, b) => a.subtotal.length - b.subtotal.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Record Type",
        dataIndex: "record_type",
        key: "record_type",
        sorter: (a, b) => a.record_type.length - b.record_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        width: "90px",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    let TableData = computed(() =>
      state.XeroUnreconciled.data.map((unreconcile) => {
        const {
          id,
          bankaccount_name,
          reference,
          banktransaction_id,
          bank_account_id,
          currency_code,
          total,
          name,
          to_date,
          from_date,
          record_type,
          action_url,
        } = unreconcile;
        return {
          key: id,
          name:name,
          bankaccount_name: (
            <div>
              {bankaccount_name}
              <br />
              From:{from_date}<br />
              To:{to_date}
            </div>
          ),
          reference: reference,
          currency_code: currency_code,
          subtotal: total,
          record_type:record_type,

          link: (
            <div class="table-actions">
              <>
                <a
                  onClick={() => openurl(banktransaction_id, bank_account_id,action_url)}
                  to="#"
                >
                  <span style="display: inline-flex !important; align-items: center !important;">
                    <sdFeatherIcons
                      type="external-link"
                      size="22"
                      color="#1890ff"
                    />{" "}
                    &nbsp;View in Xero
                  </span>
                </a>
              </>
            </div>
          ),
          action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    let ignoreTableData = computed(() =>
      state.XeroUnreconciled.data.map((unreconcile) => {
        const {
          id,
          bankaccount_name,
          reference,
          banktransaction_id,
          bank_account_id,
          currency_code,
          total,
          name,
          to_date,
          from_date,
          action_url,
        } = unreconcile;
        return {
          key: id,
          name:name,
          bankaccount_name: (
            <div>
              {bankaccount_name}
              <br />
              From:{from_date}<br />
              To:{to_date}
            </div>
          ),
          reference: reference,
          currency_code: currency_code,
          subtotal: total,
          link: (
            <div class="table-actions">
              <>
                <a
                  onClick={() => openurl(banktransaction_id, bank_account_id,action_url)}
                  to="#"
                >
                  <span style="display: inline-flex !important; align-items: center !important;">
                    <sdFeatherIcons
                      type="external-link"
                      size="22"
                      color="#1890ff"
                    />{" "}
                    &nbsp;View in Xero
                  </span>
                </a>
              </>
            </div>
          ),
          action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      exportToCSV,
      Description,
      addFormState,
      handleSearch,
      onSorting,orgdata
    };
  },
});
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style>
