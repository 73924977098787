<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
         NO VAT/ZERO RATED /EXEMPT VAT entries for review
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="List of Transactions with No VAT, Exempt VAT, Export VAT, Zero Rated VAT.</br>Account Code having Overheads, Expense, Revenue, Fixed. (Bank Transactions &amp; Invoices)" />
    <sdCards headless v-if="loading">
      
      <div class="spin">
        <a-spin /></div
    ></sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                :dataSource="ignoreTableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              />
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template> 
<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({ 
  name: "xero_transnominal",
  components: {
    Main,
    Description,
    BasicFormWrapper,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.xeroIncorrectVat.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.tax_type).toLowerCase()).includes(substring.toLowerCase()));
        // console.log(index);
        if(search_match == false){search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.transaction_type).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.tax_type).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.taxtype).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.date)).includes(substring));
        }if(search_match == false){search_match = (((data.subtotal)).includes(substring));
        }if(search_match == false){search_match = (((data.tax_amount)).includes(substring));
        }if(search_match == false){search_match = ((String(data.total)).includes(substring));
        }
        if(search_match == false){
          (state.xeroIncorrectVat.data).splice(index, 1);
        }else{
          
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index]);
        }
      });
      state.xeroIncorrectVat.data = xero_get_datas;
    };
    
    const openurl = (id,type) => {
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'RECEIVE-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;
        case 'RECEIVE':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;    
        case 'MJS':
          url = 'https://go.xero.com/Journal/View.aspx?invoiceID=';
          break;     
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        ); 
      
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.xeroIncorrectVat.data = [];
      localStorage.setItem("search_data",JSON.stringify([]));
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = () => {
      loading.value = true;
      dispatch("getincorrectvatData", "none").then(
        (response) => {
          if (response.status == 200) {
            state.xeroIncorrectVat.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.xeroIncorrectVat.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.xeroIncorrectVat.data = [];
          loading.value = false;
        }
      );
    };

    const getignoredata = () => {
      loading.value = true;
      dispatch("xeroincorrectvatIgnore", "none").then(
        (response) => {
          if (response.status == 200) {
            state.xeroIncorrectVat.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.xeroIncorrectVat.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.xeroIncorrectVat.data = [];
          loading.value = false;
        }
      );
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreincorrectvat", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });

            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        }
        // console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getvatData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.xeroIncorrectVat.data = response.data;
              loading.value = false;
            } else {
              state.xeroIncorrectVat.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.xeroIncorrectVat.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("xerovatIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.xeroIncorrectVat.data = response.data;
              loading.value = false;
            } else {
              state.xeroIncorrectVat.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.xeroIncorrectVat.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("incorrectvatignoreBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
       title: "Contact Name",
       dataIndex: "supplier",
       key: "supplier",
       sorter: (a, b) => a.supplier.length - b.supplier.length,
      sortDirections: ["descend", "ascend"],
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Tax Type",
        dataIndex: "taxtype",
        key: "taxtype",
        sorter: (a, b) => a.taxtype.length - b.taxtype.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Type",
        dataIndex: "transaction_type",
        key: "transaction_type",
        sorter: (a, b) => a.supplier.length - b.supplier.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Currency",
        dataIndex: "currency_code",
        key: "currency_code",
        sorter: (a, b) => a.currency_code.length - b.currency_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gross Amt",
        dataIndex: "gross_amt",
        key: "gross_amt",
        sorter: (a, b) => a.gross_amt.length - b.gross_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Net Amt",
        dataIndex: "net_amt",
        key: "net_amt",
        sorter: (a, b) => a.net_amt.length - b.net_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Amt",
        dataIndex: "vat_amt",
        key: "vat_amt",
        sorter: (a, b) => a.vat_amt.length - b.vat_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      // {
      //   title: "Account Name",
      //   dataIndex: "account_name",
      //   key: "account_name",
      //   sorter: (a, b) => a.account_name.length - b.account_name.length,
      //   sortDirections: ["descend", "ascend"],
      // },
      {
        title: "VAT Name",
        dataIndex: "vat_name",
        key: "vat_name",
        sorter: (a, b) => a.vat_name.length - b.vat_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },

      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    var TableData = computed(() =>
      state.xeroIncorrectVat.data.map((contact) => {
        const {
          id,
          date,
          contact_name,
          invoice_no,
          invoice_id,
          subtotal,
          tax_amount,
          taxtype,
          total,
          account_code,
          tax_type,
          invoice_type,
          transacation_id,
          transaction_type,
          action_url,
          transaction_status,
          currency_code,
        } = contact;
        return {
          key: transacation_id,
          date: date.replace('00:00:00',''),
          supplier: contact_name,
          invoice_no: invoice_no,
          invoice_type: invoice_type,
          gross_amt: subtotal,
          vat_amt: tax_amount,
          net_amt: total,
          account_name: account_code,
          vat_name: tax_type,
          taxtype:taxtype,
          transacation_id: transacation_id,
          transaction_type: transaction_type,
          action_url:action_url,
          currency_code:currency_code,
          transaction_status:transaction_status,
          link: (
              <div class="">
                <>
                  <a onClick={() => openurl(transacation_id,transaction_status)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
          action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(transacation_id, "B")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Dismiss
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
        };
      })
    );

    var ignoreTableData = computed(() =>
      state.xeroIncorrectVat.data.map((contact) => {
        const {
          id,
          date,
          contact_name,
          invoice_no,
          invoice_type,
          invoice_id,
          subtotal,
          tax_amount,
          total,
          account_code,
          tax_type,
          taxtype,
          transacation_id,
          transaction_type,
          action_url,
          transaction_status,
        } = contact;

        return {
          key: transacation_id,
          date: date.replace('00:00:00',''),
          supplier: contact_name,
          invoice_no: invoice_no,
          invoice_type: invoice_type,
          gross_amt: subtotal,
          vat_amt: tax_amount,
          net_amt: total,
          account_name: account_code,
          vat_name: tax_type,
          taxtype:taxtype,
          transacation_id: transacation_id,
          transaction_type: transaction_type,
          action_url:action_url,
          transaction_status:transaction_status,
          link: (
              <div class="">
                <>
                  <a onClick={() => openurl(transacation_id,transaction_status)} to="#">
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ),
            action: (
              <div>
                <>
                  <a-popconfirm
                    title="Are you sure ?"
                    ok-text="Yes"
                    cancel-text="No"
                    onConfirm={() => onHandleignore(transacation_id, "A")}
                  >
                    <a-button
                      size="default"
                      outlined="true"
                      raised="true"
                      type="info"
                      to="#"
                      id="dismiss_btn"
                    >
                      Active
                    </a-button>
                  </a-popconfirm>
                </>
              </div>
            ),
           
        };
      })
    );

     const exportToCSV = () => {
      const inputData = state.xeroIncorrectVat.data.map((contact) => {
       const {
             id,
          date,
          contact_name,
          invoice_no,
          invoice_id,
          subtotal,
          tax_amount,
          tax_type,
          taxname,
          taxtype,
          transaction_type,
          total,
          account_code,
          vat_name,
          vat_code,
          currency_code,
            } = contact;
        return {
         ID: id,
          Date: date,
          Supplier: contact_name,
          Invoice_no: invoice_no,
          Account_code:account_code,
          Account_name:taxname,
          Vat_code:tax_type,
          Vat_name: vat_name,
          Taxtype: taxtype,
          Currency:currency_code,
          Gross_amt: subtotal,
          Vat_amt: tax_amount,
          Type: transaction_type,
          Net_amt: total,
          
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "NO VAT/ZERO RATED /EXEMPT VAT entries for review",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " NO VAT/ZERO RATED /EXEMPT VAT entries for review " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };

    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      addFormState,
      handleSearch,
      orgdata,exportToCSV
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style>
