<template>
  <div>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            Manual Bank Transactions
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync: 
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
               <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
      </template>
      <template v-slot:buttons>
        <a-row :gutter="25" style="justify-content: center">
          <a-col>
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-group">
                  <div class="sort-group">
                    <a-select @change="onSorting" defaultValue="Active">
                      <a-select-option value="Active">Active</a-select-option>
                      <a-select-option value="Ignored"
                        >Dismissed</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
          </a-col></a-row
        >
      </template>
    </sdPageHeader>
    <Main>
      <sdCards headless v-if="loading">
        <div class="spin">
          <a-spin /></div
      ></sdCards>
      <sdCards headless v-else>
        <template v-if="selectedoption == 'Active'">
           <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    <a-button class="btn-signin" size="small" @click="exportToCSV()" >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div> 
              </TopToolBox>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="TableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                >
                </a-table>
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
        <template v-else>
              <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('A')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                        Active</span
                      >
                    </a-button>
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div> 
              </TopToolBox>
            </a-col>
          </a-row> 
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="ignoreTableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: ignoreTableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                />
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import { Main, TableWrapper } from "../../../../styled";
import {
  TopToolBox,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent, computed, ref, onMounted } from "vue";
import { dispatch, useStore } from "vuex";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default defineComponent({
  name: "xero_manualbank",
  components: {
    Main,
    TableWrapper,
    ProjectSorting,
    TopToolBox,
    UserTableStyleWrapper,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);

    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroContact.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };
    const openurl = (id) => {
      window.open("https://go.xero.com/Contacts/View/" + id, "_blank");
      // location.href = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=' + id;
    };
    const getdata = () => {
      loading.value = true;
      // dispatch("getcontactData").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroContact.data = response.data;
      //       loading.value = false;
      //     } else {
      //       loading.value = false;
      //       // onCancel();
      //     }
      //   },
      //   (error) => {
      //     state.XeroContact.data = [];
      //   }
      // );
      state.XeroContact.data = [];
       loading.value = false;
    };
    const getignoredata = () => {
      loading.value = true;
      // dispatch("xerocontactIgnore").then(
      //   (response) => {
      //     if (response.status == 200) {
      //       state.XeroContact.data = response.data;
      //       loading.value = false;
      //     } else {
      //       loading.value = false;
      //       state.XeroContact.data = [];
      //       // onCancel();
      //     }
      //   },
      //   (error) => {
      //     loading.value = false;
      //     state.XeroContact.data = [];
      //   }
      // );
      state.XeroContact.data = [];
      loading.value = false;
    };

    

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignoreContact", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };
    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreContactBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Transaction Details",
        dataIndex: "transaction_details",
        key: "transaction_details",
        sorter: (a, b) => a.transaction_details.length - b.transaction_details.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => a.amount.length - b.amount.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },  
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    let TableData = computed(() =>
      state.XeroContact.data.map((contact) => {
        const {
          id,
          date,
          transaction_details,
          amount,
          link, 
        } = contact;
        return {
          key: id, 
          date: date,
          transaction_details: transaction_details,
          amount: amount,
          link: link,  
          action: (
            <div class="table-actions">
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "B")}
                >
                  <a-button
                    class="btn-icon"
                    type="default"
                    to="#"
                    shape="circle"
                  >
                    <sdFeatherIcons type="trash-2" size="16" />
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );
    var ignoreTableData = computed(() =>
      state.XeroContact.data.map((contact) => {
          const {
          id,
          date,
          transaction_details,
          amount,
          link, 
        } = contact;
        return {
          key: id, 
          date: date,
          transaction_details: transaction_details,
          amount: amount,
          link: link,  
          action: (
            <div class="table-actions">
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "A")}
                >
                  <a-button
                    class="btn-icon"
                    type="default"
                    to="#"
                    shape="circle"
                  >
                    <sdFeatherIcons type="trash-2" size="16" />
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      })
    );

    const exportToCSV = () => {
      const inputData = state.XeroContact.data.map((contact) => {
          const {
          id,
          date,
          transaction_details,
          amount,
          link, 
        } = contact;
        return {
          Date: date,
          "Transaction Details": transaction_details,
          Amount: amount,
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "Manual Bank Transactions",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " Manual Bank Transactions " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      exportToCSV,
      onSorting,orgdata
    };
  },
});
</script>
