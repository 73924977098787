<template>
  <sdPageHeader>
    <template #title>
      <div class="product-single-description">
        <sdHeading class="product-single-title" as="h1" style="margin: 0px">
          New Contact Name 
        </sdHeading>
        <span style="margin: 0px; font-size: 16px; font-weight: 400">
          Last Sync:
          {{
            orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
          }}
        </span>
      </div>
      <a-row :gutter="25">
        <a-col>
          <div class="setting-card-title">
            <sdHeading as="h5" style="font-weight: 500">
              Transactions Between&nbsp;
              <span style="margin: 0px; font-size: 16px; font-weight: 400">
                {{ orgdata.syncdate }}
              </span>
              <a-range-picker
                v-if="orgdata.lastsync == 'not_synced'"
                style="padding-top: 10px !important"
                @change="DateSelect"
                :defaultValue="selectrange"
              />
              &nbsp;
              <a-button
                size="medium"
                type="primary"
                v-on:click="xerosync()"
                v-if="orgdata.lastsync == 'not_synced'"
              >
                <sdFeatherIcons type="refresh-cw" size="14" />
                Sync Now
              </a-button>
            </sdHeading>
          </div>
        </a-col></a-row
      >
    </template>
    <template v-slot:buttons>
      <ProjectSorting>
        <div class="project-sort-bar">
          <div class="project-sort-group">
            <div class="sort-group">
              <a-select @change="onSorting" defaultValue="Active">
                <a-select-option value="Active">Active</a-select-option>
                <a-select-option value="Ignored">Dismissed</a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </ProjectSorting>
    </template>
  </sdPageHeader>
  <Main>
    <Description text="List of New Contact name created within report range." />
    <sdCards headless v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
    <sdCards headless v-else>
      <template v-if="selectedoption == 'Active'">
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('B')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                      Dismiss</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                   <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: TableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
                
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="15" style="padding: 10px">
          <a-col :xs="24">
            <TopToolBox>
              <div class="product-single-description">
                <div
                  class="
                    product-single-title
                    page-header-actions
                    product-single-description
                  "
                  style="float: right"
                >
                  <a-button
                    class="btn-signin"
                    size="small"
                    type="default"
                    v-if="checkselected"
                    @click="bulkIgnore('A')"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                      Active</span
                    >
                  </a-button>
                  <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                  <a-button class="btn-signin" size="small"  @click="exportToCSV()">
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                </div>
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  <br />
                </span>
              </div>
            </TopToolBox>
          </a-col>
        </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
            <TableWrapper class="table-responsive">
              <a-table
                :data-source="ignoreTableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :pagination="{
                  defaultPageSize: 10,
                  total: ignoreTableData.length,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
               
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
    </sdCards>
  </Main>
</template>

<script>
import {
  ContactPageheaderStyle,
  Action,
  UserTableStyleWrapper,
  TopToolBox,
  ProjectSorting,
} from "../../style";
import { defineComponent } from "vue";
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from "../../../../styled";
import { dispatch, useStore } from "vuex";
import {
  defineAsyncComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from "./../Description.vue";
export default defineComponent({
  name: "xero_vatjournal",
  components: {
    Main,
    BasicFormWrapper,
    CardToolbox,
    Description,
    TopToolBox,
    UserTableStyleWrapper,
    TableWrapper,
    ProjectSorting,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    

    onMounted(() => {
      getdata();
  
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

	const handleSearch = (values) => {
    let substring = addFormState.search_text;
    state.XeroSup.data = JSON.parse(localStorage.getItem("search_data"));
    let xero_get_datas = [];
    (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
      let search_match = false;
      search_match = (((data.is_customer).toLowerCase()).includes(substring.toLowerCase()));
      if(search_match == false){search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
      }if(search_match == false){search_match = (((data.is_supplier).toLowerCase()).includes(substring.toLowerCase()));
      }if(search_match == false){search_match = (((data.date)).includes(substring));
      }
      if(search_match == false){
        (state.XeroSup.data).splice(index, 1);
      }else{
        
        xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index]);
      }
    });
    state.XeroSup.data = xero_get_datas;
  };

    const openurl = (id) => {
      window.open(
        "https://go.xero.com/Contacts/View/"+ id,
        "_blank"
      );
     
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.XeroSup.data = [];
      localStorage.setItem("search_data",JSON.stringify([]));
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };

    const getdata = () => { 
      loading.value = true;
      dispatch("getsupData").then(
        (response) => {
          // console.log(response);
         
          if (response.status == 200) {
            state.XeroSup.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroSup.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroSup.data = [];
          loading.value = false;
        }
      );
      //  state.XeroSup.data = [];
      //  loading.value = false;
    };

    const getignoredata = () => {
      
      loading.value = true;
      dispatch("xerosupIgnore").then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            state.XeroSup.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.XeroSup.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.XeroSup.data = [];
          loading.value = false;
        }
      );
      // state.XeroSup.data = [];
      // loading.value = false;
    };

    const onHandleignore = (id, status) => {
      
      var data = {};
      data.id = id;
      data.status = status;
      console.log(data);
      dispatch("ignoreSup", data).then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            }); 
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };

    const DateSelect = (date, dateString) => {
      if (selectedoption.value == "Active") {
        loading.value = true;
        dispatch("getsupData", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroSup.data = response.data;
              loading.value = false;
            } else {
              state.XeroSup.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroSup.data = [];
            loading.value = false;
          }
        );
      } else {
        loading.value = true;
        dispatch("xerosupIgnore", dateString).then(
          (response) => {
            if (response.status == 200) {
              state.XeroSup.data = response.data;
              loading.value = false;
            } else {
              state.XeroSup.data = [];
              loading.value = false;
              //onCancel();
            }
          },
          (error) => {
            state.XeroSup.data = [];
            loading.value = false;
          }
        );
      }
    };

    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignoreSupBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            //onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };


    // Table Columns
     const TableColumns = [
      {
        title: "Created Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
     
      /*{
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },*/
       /*{
        title: "Wrong Contact Name",
        dataIndex: "contact_name",
        key: "contact_name",
        sorter: (a, b) => a.contact_name.length - b.contact_name.length,
        sortDirections: ["descend", "ascend"],
      },*/
      {
        title: "Supplier",
        dataIndex: "is_supplier",
        key: "is_supplier",
        sorter: (a, b) => a.is_supplier.length - b.is_supplier.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Customer",
        dataIndex: "is_customer",
        key: "is_customer",
        sorter: (a, b) => a.is_customer.length - b.is_customer.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];
    
    let TableData = computed(() =>
      state.XeroSup.data.map((contact) => {
        const {
          id,
          invoice_id,
          date,
          name,
          type,
          invoice_no,
          contact_name,
          is_supplier,
          contact_id,
          is_customer,
          link,
        } = contact;
        return {
          key: id,
          invoice_id:invoice_id,
          date: date,
          name: name,
          type: type,
          invoice_no: invoice_no,
          contact_name:contact_name,
          contact_id:contact_id,
          is_supplier:is_supplier,
          is_customer: is_customer,
          
          link: (
              <div class="">
                <>
                  <a
                    onClick={() =>
                      
                      openurl(contact_id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );


     var ignoreTableData = computed(() =>
      state.XeroSup.data.map((contact) => {
        const {
          id,
          date,
          name,
          type,
          invoice_id,
          invoice_no,
          contact_name,
          is_supplier,
          is_customer,
          contact_id,
          link,
        } = contact;
        return {
          key: id,
          invoice_id: invoice_id,
          date: date,
          name: name,
          type: type,
          invoice_no: invoice_no,
          contact_name:contact_name,
          contact_id:contact_id,
          is_supplier:is_supplier,
          is_customer: is_customer,
          link: (
              <div class="">
                <>
                  <a
                    onClick={() =>
                      openurl(contact_id)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );


     const exportToCSV = () => {
      const inputData = state.XeroSup.data.map((contact) => {
        const {
          id,
          invoice_id,
          date,
          name,
          type,
          invoice_no,
          contact_name,
          is_supplier,
          is_customer,
          link,
        } = contact;
        return {
          key: id,
          invoice_id:invoice_id,
          date: date,
          contact_name: name,
          type: type,
          invoice_no: invoice_no,
          is_supplier:is_supplier,
          is_customer: is_customer,
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "New Contact Name",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " New Contact Name " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
   
    return {
      loading,
      TableColumns,
      TableData,
      ignoreTableData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      DateSelect,
      orgdata,
      addFormState,
      handleSearch,
      exportToCSV
    };
  },
});
</script>
 <style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style> 