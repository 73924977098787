<template>
  <div v-if="loading">
    <sdCards headless>
      <div class="spin">
        <a-spin />
      </div>
    </sdCards>
  </div>
  <div v-else>
    <sdPageHeader title="All Clients">
      <template v-slot:subTitle>
        <a-button size="default" type="text" style="border: none">
          <h3 style="color: black !important">Favourites</h3>
        </a-button>
        <a-button
          size="default"
          type="text"
          style="
            border: none;
            vertical-align: middle;
            transform: rotateZ(270deg);
          "
        >
          <span>
            <sdFeatherIcons
              style="line-height: 0; color: black !important"
              type="bar-chart"
              size="18"
          /></span>
        </a-button>
      </template>
    </sdPageHeader>
    <Main>
    
      <div v-if="empty">
        <sdCards headless>
          <div class="spin">
            <a-spin />
          </div>
        </sdCards>
      </div>
      <div v-else>
        <a-row :gutter="25">
          <a-col :md="24">
            <div headless v-if="empty">
              <div v-if="loading">
                <sdCards headless>
                  <div class="spin">
                    <a-spin />
                  </div>
                </sdCards>
              </div>
              <div v-else>
                <a-empty
                  image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                  :image-style="{
                    height: '160px',
                  }"
                >
                  <template v-slot:description>
                    <h3>Clients not connected yet !</h3>
                  </template>
                  <div class="page-header-actions">
                    <a-button type="primary" v-on:click="xeroconnect()">
                      add client
                    </a-button>
                  </div>
                </a-empty>
              </div>
            </div>
            <div headless v-else>
              <a-list
                style="margin: 0px"
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="usersTableData"
              >
                <template #renderItem="{ item }">
                  <ProductCard
                    class="list-view"
                    :style="{ marginBottom: '20px' }"
                  >
                    <div class="product-list" style="padding: 10px">
                      <a-row :gutter="15">
                        <a-col :md="2" :xs="24">
                          <figure style="margin-left: 20px">
                            <img
                              :style="{ width: '40px' }"
                              :src="
                                require('@/static/img/auth/Illustration.png')
                              "
                              alt=""
                            />
                          </figure>
                        </a-col>
                        <a-col
                          :md="13"
                          :xs="24"
                          v-on:click="overviewpage(item.tenent_id)"
                        >
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h6"
                              style="margin: 0px; font-size: 16px"
                            >
                              {{ item.client_name }}
                            </sdHeading>
                            <p style="margin: 0px; font-size: 14px">
                              Last Sync:
                              {{
                                item.lastsync == "not_synced"
                                  ? "Not Synced"
                                  : item.lastsync
                              }}
                            </p>
                            <p style="margin: 0px; font-size: 14px">
                              Synced Notification:
                              {{
                                item.notification
                              }}
                            </p>
                          </div>
                          
                        </a-col>
                        
                        <a-col :md="1" :xs="24">
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h6"
                              style="margin: 10px 0px 0px 20px; font-size: 16px"
                            >
                              <sdFeatherIcons type="heart" size="20"
                            /></sdHeading>
                          </div>
                        </a-col>
                        <a-col :md="2" :xs="24">
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h6"
                              style="margin: 10px 0px 0px 0px; font-size: 16px"
                            >
                              {{ item.health_score }} %
                            </sdHeading>
                          </div>
                          </a-col>
                          
                        
                        <a-col :md="1" :xs="24">
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h6"
                              style="margin: 10px 0px 0px 0px; font-size: 16px"
                            >
                              <Action class="table-actions">
                                <sdFeatherIcons
                                  v-on:click="
                                    startactive(item.id, item.tenent_id)
                                  "
                                  v-bind:class="[
                                    item.favourite ? 'active' : 'deactivate',
                                    'deactive',
                                  ]"
                                  key=""
                                  type="star"
                                  size="20px"
                                />
                              </Action>
                            </sdHeading>
                          </div>
                        </a-col>
                        <a-col :md="1" :xs="24">
                          <div class="product-single-description">
                            <sdHeading
                              class="product-single-title"
                              as="h6"
                              style="margin: 10px 0px 0px 0px; font-size: 16px"
                            >
                              <sdDropdown>
                                <template #overlay>
                                  <a
                                    href="https://go.xero.com/Dashboard/"
                                    target="_blank"
                                  >
                                    <span>View</span>
                                  </a>
                                  <router-link to="#">
                                    <a-popconfirm
                                      title="Are u sure to report sync?"
                                      ok-text="Yes"
                                      cancel-text="No"
                                      @confirm="openModal(item.tenent_id,'Report')"
                                    >
                                    <span>Report Sync</span>
                                     </a-popconfirm>
                                  </router-link>
                                  <router-link to="#">
                                    <a-popconfirm
                                      title="Are u sure to sync?"
                                      ok-text="Yes"
                                      cancel-text="No"
                                      @confirm="openModal(item.tenent_id,'Resync')"
                                    >
                                    <span >Sync </span>
                                     </a-popconfirm>
                                  </router-link>
                                  <router-link to="#">
                                    <a-popconfirm
                                      title="Are u sure to resync?"
                                      ok-text="Yes"
                                      cancel-text="No"
                                      @confirm="xeroResync(item.tenent_id,)"
                                    >
                                    <span>Unsync</span>
                                     </a-popconfirm>
                                  </router-link>
                                  <router-link to="#">
                                    <a-popconfirm
                                      title="Are you sure ?"
                                      ok-text="Yes"
                                      cancel-text="No"
                                      @confirm="xerodisconnect(item.tenent_id)"
                                    >
                                      <span>Disconnect</span>
                                    </a-popconfirm>
                                  </router-link>
                                  <router-link to="/help">
                                  <span>Help</span>
                                  </router-link>
                                </template>
                                <router-link to="#">
                                  <sdFeatherIcons
                                    type="more-vertical"
                                    size="20px"
                                    color="Black"
                                  />
                                </router-link>
                              </sdDropdown>
                            </sdHeading>
                          </div>
                        </a-col>
                        <a-col :md="2" :xs="24">
                         <HalfProgressBar :percent="item.sync_percent" />
                        </a-col>
                      </a-row>
                    </div>
                  </ProductCard>
                </template>
              </a-list>
            </div>
          </a-col>
       </a-row>
       <Modal v-show="showModal" :var="ten_id" @close-modal="showModal = false" />
       </div>

    </Main>
  </div>
</template>
<script>
import { Main, TableWrapper } from "../styled";
import { computed, ref, defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserTableStyleWrapper, Action, ProductCard } from "./style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";
import Modal from "/src/components/Modal.vue";
import HalfProgressBar from "/src/components/utilities/progressBar.vue";
import { message, Notification } from 'ant-design-vue';

export default defineComponent({
  name: "dashboard",
  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    Modal,
    HalfProgressBar,
    ProductCard,
    FontAwesomeIcon,
    Action,
  },
 
  setup() {
    let empty = ref(true);
    let loading = ref(true);
    let orgdata = ref(null);
    let showModal= ref(false);
    let reconnect=ref(false);
    var msg = "";
    const param = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    onMounted(() => {
      xerocheck();
      window.setInterval(() => {
        updatecount();
      }, 15000);
      //window.setInterval(() => {
      //  let filter_data = JSON.parse(localStorage.getItem("search_data"));
      //  if(filter_data){
      //    state.xeroclient.data = filter_data;
      //  }
     // }, 500);
    });
    
    
    var ten_id="";
    const openModal = (tenent_id,output) => {
    ten_id=tenent_id;
    var out=output;
    //alert(tenent_id);
    localStorage.setItem("ten_id", ten_id);
    localStorage.setItem("output", out);
    showModal.value=true;
    }
    const xerocheck = () => {
    localStorage.removeItem("search_data");
    localStorage.removeItem("filter_data");
      if (param.params.id) {
        dispatch("xerosave", param.params.id.toString()).then(
          (response) => {
            router.push("/");
          },
          (error) => {
            // console.log(error);
            empty.value = true;
          }
        );
        empty.value=false;
        loading.value = false;
      } else {
        dispatch("getclientlist").then(
          (response) => {
            state.xeroclient.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            //localStorage.setItem("filter_data",JSON.stringify(response.data));
            empty.value = false;
            loading.value = false;
          },
          (error) => {
            // console.log(error);
            empty.value = false;
            loading.value = false;
          }
        );
        
      }
    };

    const updatecount = () => {
      if(window.location.pathname == '/'){
        dispatch("getsyncdata").then(
          (response) => {
            response.data.map((client,index) => {
              // console.log(id);
              state.xeroclient.data[index].sync_percent = parseInt(response.data[index].sync_percent);
              state.xeroclient.data[index].notification = response.data[index].notification;
              msg = msg +", "+ response.data[index].notification;
             //Notification["success"]({
             // message:"Now Synced",
             // description: msg,
             // });
              // console.log(selected_bulk_ids);
            });
            
          },
          (error) => {
            
          }
        );
      }
    };

    const xerodisconnect = (tenent_id) => {
      dispatch("xerodisconnect",tenent_id).then(
        (response) => {
        location.reload(true);
          xerocheck();
        },
        (error) => {
        //window.location.reload(true);
          xerocheck();
          // console.log(error);
        }
      );
    };

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        name: record.name,
      }),
    };

    const showEditModal = (data) => {
      console.log(data);
    };

    const onHandleDelete = (id) => {
      console.log(id);
    };

    const handlesort = () => {
      var data = {};
      dispatch("clientdatasort", data);
    };

    const overviewpage = (id) => {
      if (id !== undefined) {
        localStorage.setItem("org", id);
        router.push({ path: "/overview" });
      }
    };
    const showpercent = (id) => {
    return id;
      
    };
    const startactive = (id, tenent_id) => {
      console.log(id);
      dispatch("onStarUpdate", { id: id, tenent_id: tenent_id }).then(
        (response) => {
          xerocheck();
        },
        (error) => {
          // console.log(error);
          empty.value = true;
        }
      );
    };
    var usersTableData = computed(() => state.xeroclient.data);

    const xeroconnect = () => {
      window.location.href = API_ENDPOINT + "xero/authorization.php?app_id=1";
    };

     const xeroResync = (tenent_id) => {

      dispatch("getResync",tenent_id).then(
        (response) => {
          location.reload()
        },
        (error) => {}
      );
    };
 const xeroreconnect = (tenent_id) => {
     dispatch("xerorefresh",tenent_id).then(
        (response) => {
        //location.reload(true);
        xerocheck();
        },
        (error) => {
        //window.location.reload(true);
          xerocheck();
          // console.log(error);
        }
      ); 
    };


    return {
      rowSelection,
      empty,
      usersTableData,
      showEditModal,
      showModal,
      openModal,
      orgdata,
      handlesort,
      startactive,
      overviewpage,
      showpercent,
      xerodisconnect,
      xeroconnect,
      xeroreconnect,
      loading,
      xeroResync
    };
  },
});
</script>
<style>
.ant-list-items {
  padding-top: 0px !important;
}

.highlighted {
  margin: 10px !important;
  border-spacing: 0 8px !important;
}

.product-list:hover {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>