<template>
  <div>
    <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 0px">
            Capitalisation without Invoice
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
            Last Sync:
            {{
              orgdata.lastsync == "not_synced" ? "Not Synced" : orgdata.lastsync
            }}
          </span>
        </div>
        <a-row :gutter="25">
          <a-col>
            <div class="setting-card-title">
              <sdHeading as="h5" style="font-weight: 500">
                Transactions Between&nbsp;
                <span style="margin: 0px; font-size: 16px; font-weight: 400">
                  {{ orgdata.syncdate }}
                </span>
                <a-range-picker
                  v-if="orgdata.lastsync == 'not_synced'"
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />
                &nbsp;
                <a-button
                  size="medium"
                  type="primary"
                  v-on:click="xerosync()"
                  v-if="orgdata.lastsync == 'not_synced'"
                >
                  <sdFeatherIcons type="refresh-cw" size="14" />
                  Sync Now
                </a-button>
              </sdHeading>
            </div>
          </a-col></a-row
        >
      </template>
      <template v-slot:buttons>
        <a-row :gutter="25" style="justify-content: center">
          <a-col>
            <ProjectSorting>
              <div class="project-sort-bar">
                <div class="project-sort-group">
                  <div class="sort-group">
                    <a-select @change="onSorting" defaultValue="Active">
                      <a-select-option value="Active">Active</a-select-option>
                      <a-select-option value="Ignored"
                        >Dismissed</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
            </ProjectSorting>
          </a-col></a-row
        >
      </template>
    </sdPageHeader>
    <Main>
      <Description text="Bank Transaction , Manual Journals & Invoices having no attachment." />
      <sdCards headless v-if="loading">
        <div class="spin">
          <a-spin /></div
      ></sdCards>
      <sdCards headless v-else>
        <template v-if="selectedoption == 'Active'">
          <a-row :gutter="15" style="padding: 10px">
           
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button
                    class="btn-signin"
                    size="small"
                    @click="exportToCSV()"
                  >
                    <span
                      style="
                        display: inline-flex !important;
                        align-items: center !important;
                      "
                    >
                      <sdFeatherIcons type="download" size="14" /> &nbsp;
                      Export</span
                    >
                  </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          
          <a-row :gutter="15">
            
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="TableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                >
                </a-table>
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="15" style="padding: 10px">
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('A')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="check-circle" size="14" /> &nbsp;
                        Active</span
                      >
                    </a-button>
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <a-button class="btn-signin" size="small">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :md="24">
              <TableWrapper class="table-responsive">
                <a-table
                  class="ant-table-striped"
                  :dataSource="ignoreTableData"
                  :rowSelection="rowSelection"
                  :columns="TableColumns"
                  :row-class-name="
                    (_record, index) =>
                      index % 2 === 1 ? 'table-striped' : null
                  "
                  :pagination="{
                    defaultPageSize: 10,
                    total: ignoreTableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }"
                />
              </TableWrapper>
            </a-col>
          </a-row>
        </template>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import { Main, TableWrapper,BasicFormWrapper } from "../../../../styled";
import {
  TopToolBox,
  Action,
  UserTableStyleWrapper,
  ProjectSorting,
} from "../../style";
import { defineComponent, computed, ref, onMounted,reactive } from "vue";
import { dispatch, useStore } from "vuex";
import { message, Notification } from "ant-design-vue";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Description from './../Description.vue'

export default defineComponent({
  name: "xero_capitalizeinvoice",
  components: {
    Main,
    BasicFormWrapper,
    Description,
    TableWrapper,
    ProjectSorting,
    TopToolBox,
    UserTableStyleWrapper,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);

    var selectedoption = ref("Active");
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));

    onMounted(() => {
      getdata();
    });

    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };

    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      let xero_get_datas = [];
      state.Xerocapitalinvoice.data = JSON.parse(localStorage.getItem("search_data"));
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.contact_name).toLowerCase()).includes(substring.toLowerCase()));
        if(search_match == false){
          search_match = (((data.bankaccount_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.tax_name).toLowerCase()).includes(substring.toLowerCase()));
        }if(search_match == false){search_match = (((data.date_string)).includes(substring));
        }if(search_match == false){search_match = (((data.line_amount)).includes(substring));
        }if(search_match == false){search_match = (((data.tax_amount)).includes(substring));
        }
        if(search_match == false){
          (state.Xerocapitalinvoice.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      state.Xerocapitalinvoice.data = xero_get_datas;
    };

    const onSorting = (selectedItems) => {
      selectedoption.value = selectedItems;
      checkselected.value = false;
      selectcount.value = null;
      selectedid.value = null;
      state.Xerocapitalinvoice.data = [];
      if (selectedItems != "Active") {
        getignoredata();
      } else {
        getdata();
      }
    };
 
 const openurl = (id,type) => {
      let url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
      switch (type) {
        case 'ACCPAY':
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCREC':
          url = 'https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=';
          break;
        case 'ACCRECCREDIT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'ACCPAYCREDIT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'RECEIVE-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND-OVERPAYMENT':
          url = 'https://go.xero.com/AccountsPayable/ViewCreditNote.aspx?creditNoteID=';
          break;
        case 'SPEND':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break; 
        case 'RECEIVE':
          url = 'https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=';
          break;    
        case 'MJS':
          url = 'https://go.xero.com/Journal/View.aspx?invoiceID=';
          break;     
        default:
          url = 'https://go.xero.com/AccountsPayable/Edit.aspx?InvoiceID=';
          break;
      }
      window.open(
          url + id,
          "_blank"
        ); 
      
    };
    const getdata = () => {
      loading.value = true;
      dispatch("getcapitalinvoiceData").then(
        (response) => {
          if (response.status == 200) {
            state.Xerocapitalinvoice.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.Xerocapitalinvoice.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          state.Xerocapitalinvoice.data = [];
          loading.value = false;
        }
      ); 
    };
    const getignoredata = () => {
      loading.value = true;
      dispatch("capitalinvoiceignoreData").then(
        (response) => {
          if (response.status == 200) {
            state.Xerocapitalinvoice.data = response.data;
            localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            loading.value = false;
            state.Xerocapitalinvoice.data = [];
            // onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.Xerocapitalinvoice.data = [];
        }
      ); 
    };

    const onHandleignore = (id, status) => {
      var data = {};
      data.id = id;
      data.status = status;
      dispatch("ignorecapitalinvoice", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows;
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + " Rows Selected";
        }
      },
      getCheckboxProps: (record) => (
        {
          disabled: record.name === "Disabled User",
          name: record.name,
        },
        console.log(record)
      ),
    };
    const bulkIgnore = (option) => {
      var data = {};
      data.selectedid = selectedid;
      data.option = option;
      dispatch("ignorecapitalinvoiceBulk", data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
              message: "Success!",
              description: "Updated.",
            });
            if (selectedoption.value != "Active") {
              getignoredata();
            } else {
              getdata();
            }
          } else {
            Notification["error"]({
              message: "Error!",
              description: "Error .",
            });
            // onCancel();
          }
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        },
        (error) => {
          checkselected.value = false;
          selectcount.value = null;
          selectedid.value = null;
        }
      );
    };
    const TableColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
        sorter: (a, b) => a.invoice_no.length - b.invoice_no.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Supplier",
        dataIndex: "supplier",
        key: "supplier",
        sorter: (a, b) => a.supplier.length - b.supplier.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Gross Amt",
        dataIndex: "gross_amt",
        key: "gross_amt",
        sorter: (a, b) => a.gross_amt.length - b.gross_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Amt",
        dataIndex: "vat_amt",
        key: "vat_amt",
        sorter: (a, b) => a.vat_amt.length - b.vat_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Account Name",
        dataIndex: "account_name",
        key: "account_name",
        sorter: (a, b) => a.account_name.length - b.account_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "VAT Name",
        dataIndex: "vat_name",
        key: "vat_name",
        sorter: (a, b) => a.vat_name.length - b.vat_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Link",
        dataIndex: "link",
        key: "link",
        sorter: (a, b) => a.link.length - b.link.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
      },
    ];

    let TableData = computed(() =>
      state.Xerocapitalinvoice.data.map((contact) => {
        const {
          id,
          date,
          invoice_no,
          invoice_id,
          type,
          contact_name,
          total,
          tax_amount,
          bankaccount_name,
          tax_name,
          link,
        } = contact;
        return {
          key: invoice_id,
          date: date,
          invoice_no: invoice_no,
          supplier: contact_name,
          gross_amt: total,
          vat_amt: tax_amount,
          account_name: bankaccount_name,
          vat_name: tax_name, 
          link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      openurl(invoice_id,type)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(invoice_id, "B")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Dismiss
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );
    
    var ignoreTableData = computed(() =>
      state.Xerocapitalinvoice.data.map((contact) => {
        const {
          id,
          date,
          invoice_no,
          invoice_id,
          type,
          contact_name,
          total,
          tax_amount,
          bankaccount_name,
          tax_name,
          link,
        } = contact;
        return {
          key: invoice_id,
          date: date,
          invoice_no: invoice_no,
          supplier: contact_name,
          gross_amt: total,
          vat_amt: tax_amount,
          account_name: bankaccount_name,
          vat_name: tax_name, 
          link: (
              <div class="table-actions">
                <>
                  <a
                    onClick={() =>
                      openurl(invoice_id,  type)
                    }
                    to="#"
                  >
                    <span style="display: inline-flex !important; align-items: center !important;">
                      <sdFeatherIcons
                        type="external-link"
                        size="22"
                        color="#1890ff"
                      />{" "}
                      &nbsp;View in Xero
                    </span>
                  </a>
                </>
              </div>
            ), 
            action: (
            <div>
              <>
                <a-popconfirm
                  title="Are you sure ?"
                  ok-text="Yes"
                  cancel-text="No"
                  onConfirm={() => onHandleignore(invoice_id, "A")}
                >
                  <a-button
                    size="default"
                    outlined="true"
                    raised="true"
                    type="info"
                    to="#"
                    id="dismiss_btn"
                  >
                    Active
                  </a-button>
                </a-popconfirm>
              </>
            </div>
          ), 
        };
      })
    );

    const exportToCSV = () => {
      const inputData = state.Xerocapitalinvoice.data.map((contact) => {
        const {
          id,
          date,
          invoice_no, 
          contact_name,
          total,
          tax_amount,
          bankaccount_name,
          account_code,
          tax_name, 
        } = contact;

        return {
          Date: date,
          Invoice : invoice_no,
          "Contact Name": contact_name,
          "Gross Amt": total,
          "VAT Amt": tax_amount,
          "Vat Name": tax_name,
          "Account Name": bankaccount_name,
          "Account Code": account_code,
        };
      });
      var linedata1 = [];
      linedata1.push({
      Point : "Capitalisation Without Invoice",
      Client: orgdata.value.client_name,
      "Synced Period": orgdata.value.syncdate,
      });
      const fileName =
        orgdata.value.client_name + " Capitalisation Without Invoice " + orgdata.value.syncdate;
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(linedata1);
      XLSX.utils.sheet_add_json(ws,inputData,{ skipHeader: false, origin: "A" + (linedata1.length + 3) });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };


    return {
      loading,
      TableColumns,
      TableData,
      rowSelection,
      selectcount,
      ignoreTableData,
      checkselected,
      selectedoption,
      bulkIgnore,
      onSorting,
      addFormState,
      handleSearch,
      orgdata,exportToCSV,
    };
  },
});
</script>
 <style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #968f8f34;
}
#dismiss_btn {
  color: white;
  background-color: #5f63f2;
}
</style> 